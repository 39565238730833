import React from "react"
import { TM } from "../components/common/typography/trademark"

export const NEWS_DATA = [
  {
    snippet: <>01 Communique Announces Partnership with Real Matter</>,
    date: "January 21, 2025",
    img: "real_matter.jpg",
    imgmin: "real_matter.jpg",
    url: "https://www.01com.com/pdf/2025/Partnership-Real-Matter.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique Partners with Keyfactor to Provide Quantum-Safe X.509
        Certificates
      </>
    ),
    date: "July 10, 2023",
    img: "keyfactor.png",
    imgmin: "keyfactor.png",
    url:
      "https://01com.com/pdf/2023/Keyfactor_01_Partnership_Press_Release.pdf",
    additionalStyles: { backgroundSize: "380px" },
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique Releases IronCAP X<TM /> v2.0 - World's First QuantumSafe
        End-to-End Email Security Service for Enterprises
      </>
    ),
    date: "June 14, 2023",
    img: "demo.png",
    imgmin: "demo.png",
    url: "https://01com.com/pdf/2023/Press-release-ICX-v2.0.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Announces Receipt of a Notice of Allowance from the USPTO for its Quantum-Safe Wallet Patent Application",
    date: "April 4, 2023",
    img: "quantum-safe-wallet.jpeg",
    imgmin: "quantum-safe-wallet.jpeg",
    url: "https://www.01com.com/pdf/2023/Press-Release-April-2023-Patent.pdf",
    tag: "Announcements",
  },
  {
    snippet: "Incorporating Post-Quantum Cryptography for Thales Luna HSMs",
    date: "January 17, 2023",
    img: "thales-marketing.jpg",
    imgmin: "thales-marketing.jpg",
    url:
      "https://www.01com.com/pdf/2023/Thales-and-01-Communique-Joint-Press-Release.pdf",
    tag: "Announcements",
  },
  {
    snippet: "01 Communique Announces Completion of a Quantum-Safe Wallet",
    date: "October 27, 2022",
    img: "crypto-wallet.png",
    imgmin: "crypto-wallet.png",
    url: "https://www.01com.com/pdf/2022/Press-release-Wallet-October-2022.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        IronCAP
        <TM /> Completes PoC of Quantum-Safe Blockchain
      </>
    ),
    date: "July 27, 2022",
    img: "solana-poc.jpeg",
    imgmin: "solana-poc.jpeg",
    url:
      "https://www.01com.com/pdf/2022/Crypto_JV_Press_Release_POC_Complete.pdf",
    tag: "Announcements",
  },
  {
    snippet: "Paper on Email Phishing: Culprit Behind Ransomware",
    date: "May 3, 2022",
    img: "pwc-whitepaper.jpeg",
    imgmin: "pwc-whitepaper.jpeg",
    url: "https://www.01com.com/pdf/2022/Educational-paper-announcement.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Announces Patent Filing of its Quantum-Safe Steganography Technology aiming for NFTs, Digital Wallets, etc.",
    date: "April 26, 2022",
    img: "steganography.jpeg",
    imgmin: "steganography.jpeg",
    url: "https://www.01com.com/pdf/2022/QS_Steganography_Press_Release.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique commissioned to develop World's First Quantum-Safe Blockchain for Tokens, NFTs, Smart Contracts for Metaverse, De-centralized Finance (DeFi)",
    date: "April 5, 2022",
    img: "tokens.jpeg",
    imgmin: "tokens.jpeg",
    url: "https://www.01com.com/pdf/2022/Crypto_JV_Press_Release.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Partners with Thales to Provide Quantum-Safe Cryptographic Solutions",
    date: "February 8, 2022",
    img: "thales.png",
    imgmin: "thales.png",
    url:
      "https://www.01com.com/pdf/2022/Thales_01_Partnership_Press_Release.pdf",
    additionalStyles: { backgroundSize: "380px" },
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Awarded Editor's Choice as Top Remote Access Company 2021",
    date: "November 17, 2021",
    img: "enterprise-award-cert.jpeg",
    imgmin: "enterprise-award-cert.jpeg",
    url: "https://www.01com.com/pdf/2021/Press-Release-Award-IIT.pdf",
    additionalStyles: { backgroundSize: "850px" },
    tag: "Awards",
  },
  {
    snippet:
      "01 Communique Announces Receipt of Notice of Allowance from USPTO for its Patent Application",
    date: "November 9, 2021",
    img: "uspto-patent.jpeg",
    imgmin: "uspto-patent.jpeg",
    url:
      "https://www.01com.com/pdf/2021/November-2021-Patent-Notice-of-Allowance.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Announces the Release of DoMobile v4 by Hitachi Solutions Create, Ltd. for Booming Remote Work Trend in Japan",
    date: "July 28, 2021",
    img: "domobile2.png",
    imgmin: "domobile2.png",
    url: "https://01com.com/pdf/2021/Hitachi-Press-Release-DoMobile.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique announces new IronCAP
        <TM /> Toolkits for Mobile Platforms
      </>
    ),
    date: "July 13, 2021",
    img: "android-ios.jpeg",
    imgmin: "android-ios.jpeg",
    url: "https://www.01com.com/pdf/2021/Press-Release-ICC-Updated.pdf",
    tag: "Announcements",
  },
  {
    snippet: "Educational Paper on Rethinking Cybersecurity in a Quantum World",
    date: "July 6, 2021",
    img: "pwc-whitepaper.jpeg",
    imgmin: "pwc-whitepaper.jpeg",
    url: "https://www.01com.com/pdf/2021/Educational-paper-announcement.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique to Supply IronCAP Technology to Polydigi Tech to Develop Quantum-Safe Automotive Mobile Keyless Solution",
    date: "June 28, 2021",
    img: "biometrics.jpeg",
    imgmin: "biometrics.jpeg",
    url: "https://www.01com.com/pdf/2021/Press-Release-Polydigi.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "DigiFlynt takes IronCAP Technology to GCC Countries and the West African Region",
    date: "June 16, 2021",
    img: "digiflynt-gcc-africa.jpeg",
    imgmin: "digiflynt-gcc-africa.jpeg",
    url:
      "https://www.01com.com/pdf/2021/Press-Release-Digiflynt-Activities.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Partners with ixFintech and Polydigi Tech to Augment ixWallet Against Mounting Cyber Security Risks",
    date: "May 26, 2021",
    img: "ewallet.png",
    imgmin: "ewallet.png",
    url: "https://www.01com.com/pdf/2021/eWallet-Press-Release.pdf",
    additionalStyles: { backgroundSize: "250px" },
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique Signs Partnership Agreement with rThreat Inc. to Sell
        IronCAP
        <TM /> Technology
      </>
    ),
    date: "April 6, 2021",
    img: "rthreat.png",
    imgmin: "rthreat.png",
    url: "https://www.01com.com/pdf/2021/Press-Release-rThreat.pdf",
    additionalStyles: { backgroundSize: "250px" },
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        Bounty Contest Results Re-enforced IronCAP
        <TM />
        's Resilience
      </>
    ),
    date: "March 25, 2021",
    img: "bounty-results.jpeg",
    imgmin: "bounty-results.jpeg",
    url:
      "https://www.01com.com/pdf/2021/Press-Release-BountyContest-2021-Results.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique Signs Partnership Agreement with DigiFlynt to Sell IronCAP
        <TM /> Technology
      </>
    ),
    date: "March 23, 2021",
    img: "digiflynt.png",
    imgmin: "digiflynt.png",
    url:
      "https://www.01com.com/pdf/2021/Press-Release-Digiflynt-March-23-2021.pdf",
    additionalStyles: { backgroundSize: "250px" },
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        IronCAP
        <TM /> Hosting “Rethinking Cybersecurity in a Quantum World” Webinar and
        Announcing Bounty Contest Results
      </>
    ),
    date: "March 22, 2021",
    img: "bounty-end.jpeg",
    imgmin: "bounty-end.jpeg",
    url: "https://www.01com.com/pdf/2021/Bount-Contest-Officially-Closed.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique Signs Partnership Agreement with ISA Cybersecurity to Sell
        IronCAP
        <TM /> Technology
      </>
    ),
    date: "March 18, 2021",
    img: "isa-logo.png",
    imgmin: "isa-logo.png",
    url:
      "https://www.01com.com/pdf/2021/ISA-CyberSecurity-01%20Communique-partnership.pdf",
    additionalStyles: { backgroundSize: "250px" },
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Announces Bounty Contest 2021 Exclusively Sponsored by PwC China",
    date: "January 18, 2021",
    img: "pwc-hackathon.jpg",
    imgmin: "pwc-hackathon.jpg",
    url:
      "https://www.01com.com/pdf/2021/Press-Release-PWC-Hackathon-Sponsorship.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique Partners with U.K. based Mirata Ltd. to Provide IronCAP
        <TM /> Cybersecurity Solutions
      </>
    ),
    date: "December 14, 2020",
    img: "mirata.png",
    imgmin: "mirata.png",
    url: "https://www.01com.com/pdf/2020/Mirata-Partnership-Dec-14-2020.pdf",
    additionalStyles: { backgroundSize: "200px" },
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Partners with ixFintech to Deliver New Quantum-safe Bitcoin ATM machine",
    date: "December 8, 2020",
    img: "atm.jpg",
    imgmin: "atm.jpg",
    url: "https://www.01com.com/pdf/2020/Quantum-safe-Bitcoin-ATM-machine.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique to Provide Business Update Including Long-term Strategic Growth Plans",
    date: "November 9, 2020",
    img: "webinar.jpg",
    imgmin: "webinar.jpg",
    url: "https://www.01com.com/pdf/2020/Advisory-Release-November-9th.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique Signs a Co-operation agreement with PwC to Implement
        Quantum-Safe IronCAP
        <TM /> Cryptography Technology
      </>
    ),
    date: "November 2, 2020",
    img: "pwc.png",
    imgmin: "pwc.png",
    url: "https://www.01com.com/pdf/2020/Joint-Press-Release-PWC.pdf",
    additionalStyles: { backgroundSize: "90%" },
    tag: "Announcements",
  },
  {
    snippet: "01 Communique Commences Trading on OTCQB",
    date: "October 19, 2020",
    img: "otc.png",
    imgmin: "otc.png",
    url: "https://www.01com.com/pdf/2020/OTCQB-release-October-19.pdf",
    additionalStyles: { backgroundSize: "250px" },
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Announces a $5 Million Equity Facility with Alumina Partners",
    date: "October 7, 2020",
    img: "alumina.png",
    imgmin: "alumina.png",
    url: "http://01com.com/pdf/2020/Alumina-press-release.pdf",
    additionalStyles: { backgroundSize: "250px" },
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique provides FREE Live Demo of IronCAP
        <TM /> Post-Quantum Cryptography Technology
      </>
    ),
    date: "September 23, 2020",
    img: "demo.jpg",
    imgmin: "demo.jpg",
    url:
      "https://www.01com.com/pdf/2020/01-Communique-Announces-live-demo-of-IronCAP.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique announces updated IronCAP X<TM /> v1.1 encrypted email
        platform with seamless sending mechanism and end-to-end encryption
      </>
    ),
    date: "August 20, 2020",
    img: "version1-1.png",
    imgmin: "version1-1.png",
    url: "https://www.01com.com/pdf/2020/IronCAPX-v1.1-release.pdf",
    additionalStyles: { backgroundPosition: "0% 0%" },
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique signs Partnership Agreement with Cybersecurity firm
        Privacy Horizon Inc. to sell IronCAP
        <TM /> products
      </>
    ),
    date: "August 4, 2020",
    img: "phi.png",
    imgmin: "phi.png",
    url:
      "https://www.01com.com/pdf/2020/Partnership-agreeemnt-with-Privacy-Horizons.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique signs Partnership Agreement to showcase IronCAP
        <TM /> cybersecurity
      </>
    ),
    date: "July 20, 2020",
    img: "cgi.jpg",
    imgmin: "cgi.jpg",
    url: "https://www.01com.com/pdf/2020/Partnership-July-2020.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Announces Receipt from the USPTO for its Patent Application for a Seamless Email Encryption System",
    date: "June 8, 2020",
    img: "uspto.jpg",
    imgmin: "uspto.jpg",
    url: "https://www.01com.com/pdf/2020/Patent-Application-June-8-2020.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique provides demo link for IronCAP X<TM /> email platform with
        free version now available
      </>
    ),
    date: "April 27, 2020",
    img: "free-trial.jpg",
    imgmin: "free-trial.jpg",
    url:
      "https://www.01com.com/pdf/2020/IronCAP-X%20free-version-available.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique reports new IronCAP X<TM /> product is designed to prevent
        massive privacy breaches associated with non-encrypted emails
      </>
    ),
    date: "April 16, 2020",
    img: "privacy.jpg",
    imgmin: "privacy-min.jpg",
    url:
      "https://www.01com.com/pdf/2020/IronCAP-X-to-prevent-massive-privacy-breaches.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Enters into a Memorandum of Understanding with Nexusguard Consulting Limited",
    date: "April 8, 2020",
    img: "nexusguard.jpg",
    imgmin: "nexusguard.jpg",
    url: "https://www.01com.com/pdf/2020/NexusGuard-press-release.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique invites email users to try IronCAP X<TM /> personal usage
        email platform after April 23rd launch
      </>
    ),
    date: "April 6, 2020",
    img: "email.jpg",
    imgmin: "email-min.jpg",
    url: "https://www.01com.com/pdf/2020/Try-out-IronCAP-X-press-release.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique signs Technology Licensing Agreement with Hitachi Solutions Create, Ltd. in Japan",
    date: "March 31, 2020",
    img: "hitachi.jpg",
    imgmin: "hitachi.jpg",
    url:
      "https://www.01com.com/pdf/2020/Signs-Technology-Licensing-Agreement-with-Hitachi.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique announces live demo of IronCAP X<TM /> personal usage
        email platform at Company’s Annual Shareholder Meeting
      </>
    ),
    date: "March 23, 2020",
    img: "demo.png",
    imgmin: "demo.png",
    url: "https://www.01com.com/pdf/2020/IronCAP-X-live-demo.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique announces the launch of IronCAP X<TM /> free version
        personal usage quantum-safe email encryption this April in Toronto
      </>
    ),
    date: "March 16, 2020",
    img: "launch.png",
    imgmin: "launch.png",
    url:
      "https://www.01com.com/pdf/2020/IronCAP-X-free-version-for-personal-usage-launch.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique partners with Hitachi Solutions Create, Ltd. to offer 2 free months of DoMobile Remote Access Service in Response to Coronavirus in Japan",
    date: "March 9, 2020",
    img: "domobile.jpg",
    imgmin: "domobile-min.jpg",
    url: "https://www.01com.com/pdf/2020/Hitachi-DoMobile-press-release.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        01 Communique Participates in CCTX Conference to Showcase IronCAP X
        <TM /> Product as a Step Towards Quantum Safe Encryption
      </>
    ),
    date: "March 2, 2020",
    img: "cctx.jpg",
    imgmin: "cctx-min.jpg",
    url: "https://www.01com.com/pdf/2020/CCTX-conference-press-release.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        IronCAP
        <TM /> Quantum-Safe Encryption Proves to be Un-hackable in Hacking
        Challenge
      </>
    ),
    date: "December 16, 2019",
    img: "safe.jpg",
    imgmin: "safe-min.jpg",
    url: "https://www.01com.com/pdf/Press-Release-Hackathon-Results.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "Quantum Hackathon With $100,000 Prize Receives Overwhelming Response",
    date: "November 21, 2019",
    img: "hackathon-response.jpg",
    imgmin: "hackathon-response-min.jpg",
    url: "https://www.01com.com/pdf/Press-Release-Hackathon-Update.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Enters into a Memorandum of Understanding with ixFintech Limited of Hong Kong",
    date: "November 19, 2019",
    img: "mou.jpg",
    imgmin: "mou-min.jpg",
    url: "https://www.01com.com/pdf/Press-release-ixFintech-Nov-19-2019.pdf",
    tag: "Announcements",
  },
  {
    snippet: "$100,000 Prize Quantum Hackathon Challenge Begins",
    date: "November 12, 2019",
    img: "hackathon-kickoff.jpg",
    imgmin: "hackathon-kickoff-min.jpg",
    url: "https://www.01com.com/pdf/Press-Release-Hackathon-Nov-2019.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "Confident Canadian CEO Andrew Cheung Offers $100,000 Prize in Quantum Hackathon Challenge",
    date: "October 29, 2019",
    img: "money.jpg",
    imgmin: "money-min.jpg",
    url: "https://www.01com.com/pdf/Press-Release-Hackathon.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        IronCAP
        <TM /> Awarded CIOReview’s Editor's Choice as Most Promising Quantum
        Solution
      </>
    ),
    date: "September 3, 2019",
    img: "cioreview-award.jpg",
    imgmin: "cioreview-award-min.jpg",
    url: "https://01com.com/pdf/IronCAP-Awarded-CIOReview.pdf",
    tag: "Awards",
  },
  {
    snippet: (
      <>
        IronCAP
        <TM /> Challenges Top Hackers with Availability of New “Quantum Safe”
        API
      </>
    ),
    date: "August 13, 2019",
    img: "api-launch.jpg",
    imgmin: "api-launch-min.jpg",
    url: "https://www.01com.com/pdf/API-August-2019.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "01 Communique Announces Closing of Non-brokered Private Placement",
    date: "July 29, 2019",
    img: "financing.jpg",
    imgmin: "financing-min.jpg",
    url: "https://www.01com.com/pdf/Financing-July-2019.pdf",
    tag: "Announcements",
  },
  {
    snippet: "New Website IronCAP.ca is Now Available",
    date: "July 9, 2019",
    img: "website-launch.jpg",
    imgmin: "website-launch-min.jpg",
    url: "https://www.01com.com/pdf/Web-Site-Release-July8-2019.pdf",
    tag: "Announcements",
  },
  {
    snippet: "Threat from Quantum Computer is Imminent",
    date: "May 14, 2019",
    img: "threat.jpg",
    imgmin: "threat-min.jpg",
    url: "https://www.01com.com/pdf/Release-dated-May14-2019.pdf",
    tag: "Announcements",
  },
  {
    snippet: (
      <>
        Announcement and Demonstration of IronCAP X<TM />
      </>
    ),
    date: "April 25, 2019",
    img: "demonstration.jpg",
    imgmin: "demonstration-min.jpg",
    url: "https://www.01com.com/pdf/IronCAP-X-demonstration-April-2019.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "Patent application has been filed for a Cryptographic System and Method",
    date: "February 11, 2019",
    img: "blockchain.png",
    imgmin: "blockchain-min.png",
    url: "https://www.01com.com/pdf/Patent-Application-Feb-2019.pdf",
    tag: "Announcements",
  },
  {
    snippet: "Announced MOU with Hitachi Solutions Create, Ltd. in Japan",
    date: "October 2, 2018",
    img: "analytics.png",
    imgmin: "analytics-min.png",
    url: "https://www.01com.com/pdf/Hitachi-MOU-press-release.pdf",
    tag: "Announcements",
  },
  {
    snippet: "Appointment of new Board Member",
    date: "September 26, 2018",
    img: "boardmember.jpg",
    imgmin: "boardmember-min.jpg",
    url: "https://www.01com.com/pdf/Board%20Change%20Sept%2026%202018.pdf",
    tag: "Announcements",
  },
  {
    snippet:
      "Announced closing of the previously announced private placement with GMP Limited",
    date: "September 18, 2018",
    img: "handshake.jpeg",
    imgmin: "handshake-min.jpeg",
    url:
      "https://www.01com.com/pdf/RGMP%20Financing%20closing%20(Sep%202018).pdf",
    tag: "Announcements",
  },
  {
    snippet: "Private Placement arrangement with GMP Limited",
    date: "July 23, 2018",
    img: "deal.jpg",
    imgmin: "deal-min.jpg",
    url: "https://www.01com.com/pdf/RGMP%20Financing%20July%202018.pdf",
    tag: "Announcements",
  },
  {
    snippet: "Appointment of new Board Member",
    date: "July 10, 2018",
    img: "boardmember2.jpg",
    imgmin: "boardmember2-min.jpg",
    url: "https://www.01com.com/pdf/New-Board-Member-July-9-2018.pdf",
    tag: "Announcements",
  },
  {
    snippet: "Announced Investor Relationship arrangement",
    date: "June 25, 2018",
    img: "marketing.jpg",
    imgmin: "marketing-min.jpg",
    url: "https://www.01com.com/pdf/IR-release-June-21-2018.pdf",
    tag: "Announcements",
  },
  {
    snippet: "Announced commitment to Post-Quantum Blockchain development",
    date: "June 21, 2018",
    img: "blockchaindevelopment.jpg",
    imgmin: "blockchaindevelopment-min.jpg",
    url: "https://www.01com.com/pdf/Q2-2018-press-release.pdf",
    tag: "Announcements",
  },
]
